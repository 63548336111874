import Carousel from '../components/Carousel';
import { imagePaths } from '../content/images/imagePaths';
import Footer from '../components/Footer';
import MassesTile from '../components/tiles/MassesTile';
import VideoTile from '../components/tiles/VideoTile';
import NewsWithImageTile from '../components/tiles/NewsWithImageTile';

const MainPage = () => {
    const { homePagePhotos } = imagePaths();
    
    return (
        <div className="d-flex flex-column min-vh-100 bg-gradient">
          <Carousel images={homePagePhotos} />

          <NewsWithImageTile 
            imageUrl='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2FhomepageTiles%2F20241229_104607.jpg?alt=media&token=49f1890e-64cb-4d1a-8b73-4505f18ce7bb'
            text={<>Sbor naší farnosti hledá do svých řad nové zpěváky a zpěvačky do všech kategorií, soprán, alt, tenor i bas.<br/>
              Pokud byste měli zájem se připojit, neváhejte se nám ozvat na email <strong>farnosthosteradice@gmail.com</strong></>}
            title='Farní sbor hledá nové zpěváky'/>

          <MassesTile />

          <Footer />
      </div>
    );
  };

  export default MainPage;