interface IProps {
    title: string;
    text: React.ReactNode;
    imageUrl: string;
    button?: IButtonProps
}

interface IButtonProps {
    label: string;
    url: string;
}

const NewsWithImageTile : React.FunctionComponent<IProps> = (props) => {

    return (
        <div className='container homepage-content-card-background'>
            <section id="info" className="info-section container py-5" >
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={props.imageUrl} alt="Info Image 1" className="img-fluid image-shadow" />
                    </div>
                    <div className="col-md-6 text-container">
                        <h3>{props.title}</h3>
                        <p>{props.text}</p>
                        {props.button && <a href={props.button.url} className="btn btn-primary">{props.button.label}</a>}
                    </div>
                </div>
            </section>
      </div>
    )
}

export default NewsWithImageTile;